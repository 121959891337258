import { Account, Client, Databases } from 'appwrite';

const client = new Client();
client
    .setEndpoint('https://cloud.appwrite.io/v1') //Appwrite endpoint
    .setProject('668bcf0f0032baae4f49')
    .setLocale("de"); //Appwrite project ID

const databases = new Databases(client);
const account = new Account(client);

console.log(client);
export {client, databases, account};