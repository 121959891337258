import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  function navigateTo(dest) {
    navigate(`/${dest}`);
  }

  return(
    <footer className='Footer'>
      <ul>
        <li><p onClick={() => {navigateTo("teilnahmebedingungen")}}>Teilnahmebedingungen</p></li>
        <li><p onClick={() => {navigateTo("impressum")}}>Impressum</p></li>
        <li><p onClick={() => {navigateTo("datenschutz")}}>Datenschutz</p></li>
      </ul>
    </footer>
  );
}