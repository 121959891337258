import {React, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import "./EmailStep.css";
import sendSurveyData from "../data/dataSender";
import makeVerification from "../data/verification";

export default function EmailStep({answers, setAnswers}) {
    let name_value = "";
    let email_value = "";
    const [isNewsletter, setIsNewsletter] = useState(false);
    const [isAgreement, setIsAgreement] = useState(false);
    const [clickedSend, setClickedSend] = useState(false);
    const navigate = useNavigate();

    //TODO: reduce to one function for input change
    function handleNameChange(input) {
        console.log(answers);
        name_value = input.target.value;
        setAnswers({...answers, name: name_value});
    }

    function handleEmailChange(input) {
        console.log(answers);
        email_value = input.target.value;
        setAnswers({...answers, email: email_value});
    }

    function handleNewsletterChange() {
        console.log(isNewsletter);
        setAnswers({...answers, newsletter: !isNewsletter});
        setIsNewsletter(!isNewsletter);
    }

    function handleAgreementChange() {
        console.log(answers);
        setAnswers({...answers, bedingungsannahme: !isAgreement});
        setIsAgreement(!isAgreement);
    }

    async function finishSurvey() {
        if (!clickedSend) {
            setClickedSend(true);
            console.log(answers);
            console.log(setAnswers);
            console.log(answers.name, answers.email);
            //TODO: set the user id in server function
            /*if(await sendSurveyData(answers)) {
                console.log("survey data was sent");
                navigate("/thx");
            } else {
                navigate("/error");
            }*/
            const user_id = await makeVerification(answers.name, answers.email);
            if (user_id) {
                console.log("user_id not null");
                if(await sendSurveyData({...answers, userID: user_id})) {
                    console.log("survey data was sent");
                    navigate("/thx");
                } else {
                    navigate("/error");
                }
            } else {
                navigate("/error");
            }
            /*if(await sendSurveyData({...answers, userID: user_id})) {
                navigate("/thx");
            } else {
                navigate("/error");
            }*/
            }
    }

    return(
    <div className="email-step">
        <form onSubmit={async (e) => {e.preventDefault(); await finishSurvey()}}>
                <input
                    type="text"
                    onChange={handleNameChange}
                    placeholder="Name"
                    required
                />
                <input
                    type="email"
                    onChange={handleEmailChange}
                    placeholder="E-mail"
                    required
                />
                <div className="check-wrapper">
                <div className="checks">
                    <input
                        className="checkboxes"
                        name="newsletter_check"
                        type="checkbox"
                        onChange={handleNewsletterChange}
                    />
                    <label>
                        Ja, ich möchte mich für den Newsletter
                        von Lederwarenacker anmelden
                    </label>
                </div>
                <div className="checks">
                    <input
                        className="checkboxes"
                        name="agreement_check"
                        type="checkbox"
                        onChange={handleAgreementChange}
                        required
                    />
                    <label>
                        Ja, ich habe die <Link to="/teilnahmebedingungen" className="link">Teilnahmebedingungen</Link> und
                        &thinsp;<Link to="/datenschutz" className="link">Datenschutzbestimmungen</Link> gelesen und verstanden und
                        akzeptiere diese. Ich bestätige, dass ich über 18 Jahre alt
                        bin und mein Erstwohnsitz in Deutschland liegt.
                    </label>
                </div>
                </div>
            <button className="submit-button" type="submit">
                {clickedSend ? "Bitte warten" : "Abschicken"}
            </button>
        </form>
    </div>
    );
}