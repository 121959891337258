import React from "react";
import "./Feedback.css";

export default function Feedback({step, setStep, answers, setAnswers}) {
    let text = "";

    function handleTextChange(input) {
        text = input.target.value;
    }

    function handleClick() {
        if (text != "") {
            setAnswers({...answers, feedback: text});
        }
        setStep(step + 1);
    }
    return(
        <div className="feedback">
            <textarea rows={6} onInput={handleTextChange} placeholder="Dein Feedback an uns"/>
            <button onClick={handleClick}>weiter&nbsp;👉</button>
        </div>
    );
}