import React from "react";
import "./404.css";

export default function PageNotFound() {
    return(
        <div className="page-not-found">
            <h1>404</h1>
            Seite nicht gefunden
        </div>
    );
}