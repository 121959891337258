import {React, useState} from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./Survey.css";
import Footer from "../Footer.js";
import questions from "../../assets/questions";
import SingleChoiceAnswer from "../SingleChoiceAnswer";
import ProgressBar from "../ProgressBar.js";
import EmailStep from "../EmailStep.js";
import Feedback from "../Feedback.js"
import logo from '../../assets/Logo_vs2_gold.png';

export default function Survey() {
    const [step, setStep] = useState(0);
    const [answers, setAnswers] = useState({});
    
    return(
        <div className="Survey">
            <div className="content">
            <div className="imageWrapper-survey">
            <img src={logo} className="App-logo" alt="logo" />
            </div>
                <ProgressBar currentStep={step}/>
                <TransitionGroup>
                <CSSTransition
                    key={step}
                    timeout={1000}
                    classNames="fade"
                    mountOnEnter>
                {
                    <div>
                        <div className="survey-step">
                            <h2>{questions[step]}</h2>
                        </div>
                        {step !== 5 ?
                            step !== 6 ?
                                <SingleChoiceAnswer step={step} setStep={setStep} answers={answers} setAnswers={setAnswers}/> :
                                <EmailStep answers={answers} setAnswers={setAnswers}/>
                            :
                                <Feedback step={step} setStep={setStep} answers={answers} setAnswers={setAnswers}/>
                        }
                    </div>
                }
                </CSSTransition>
            </TransitionGroup>
            </div>
            <Footer/>
        </div>
    );
}