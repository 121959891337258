import React from "react";
import "./Impressum.css";
import Markdown from "react-markdown";

export default function Impressum() {
    const markdown = `# Impressum

# Angaben gemäß § 5 TMG

Lederwaren Acker GmbH  
Königstraße 28  
70173 Stuttgart  
Handelsregister: HRB 10951  
Registergericht: Amtsgericht Stuttgart  
Vertreten durch:  
Rainer und Doris Rudolph  
Christoph Achenbach

# Kontakt

Telefon: \+49 (0) 711 228585  
Telefax: \+49 (0) 711 2285885  
E-Mail: info@lederwaren-acker.de

# Umsatzsteuer-ID

Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:  
DE 147 829 719

# Redaktionell Verantwortlicher

Lederwaren Acker GmbH  
Christoph Achenbach  
Königstraße 28  
70173 Stuttgart

# EU-Streitschlichtung

Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: [https://ec.europa.eu/consumers/odr](https://ec.europa.eu/consumers/odr).  
Unsere E-Mail-Adresse finden Sie oben im Impressum.

# Verbraucherstreitbeilegung / Universalschlichtungsstelle

Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. Zuständig ist die Universalschlichtungsstelle des Zentrums für Schlichtung e.V., Straßburger Straße 8, 77694 Kehl am Rhein ([https://www.verbraucher-schlichter.de](https://www.verbraucher-schlichter.de/)).

# Haftung für Inhalte

Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.  
Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.

# Urheberrecht

Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.  
Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.`;
    return(<Markdown>{markdown}</Markdown>);
}