const questions = [
    "War das Ihr erster Besuch bei Lederwaren Acker?",
    "Wie bewerten Sie unseren Service?",
    "Wie gefiel Ihnen das Ambiente im Laden?",
    "Wie zufrieden waren Sie mit unserer Auswahl?",
    "Wie alt sind Sie?",
    "Gibt es sonst noch etwas das Sie uns mitteilen möchten?",
    "Um Sie bei Gewinn kontaktieren zu können, brauchen wir noch eine E-Mail-Adresse",
];

export default questions;