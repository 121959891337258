import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Start from "./components/pages/Start"
import Survey from "./components/pages/Survey"
import Thx from "./components/pages/Thx"
import Error from "./components/pages/Error"
import PageNotFound from './components/pages/404';
import Verify from './components/pages/Verify';
import Teilnahmebedingungen from './components/pages/Teilnahmebedingungen';
import Impressum from './components/pages/Impressum';
import Datenschutz from './components/pages/Datenschutz';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Start/>}/>
        <Route path="/survey" element={<Survey/>}/>
        <Route path='/thx' element={<Thx/>}/>
        <Route path='/error' element={<Error/>}/>
        <Route path='/verify' element={<Verify/>}/>
        <Route path='/teilnahmebedingungen' element={<Teilnahmebedingungen/>}/>
        <Route path='/impressum' element={<Impressum/>}/>
        <Route path='/datenschutz' element={<Datenschutz/>}/>
        <Route path='*' element={<PageNotFound/>}/>
      </Routes>
    </Router>
  );
}