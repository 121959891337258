import {React, useEffect, useState} from "react";
import "./SingleChoiceAnswer.css";

export default function SingleChoiceAnswer({step, setStep, answers, setAnswers}) {
    const answerTexts = [
        ["Ja", "Nein"],
        ["😍 Sehr gut", "🙂 Gut", "😐 Mäßig", "😤 Schlecht"],
        ["🤩 Sehr gut", "😁 Gut", "🤨 Mäßig", "😒 Schlecht"],
        ["👍 Sehr zufrieden", "✌ Zufrieden", "🖐 Eher unzufrieden", "👎 Unzufrieden"],
        ["18 - 24", "25 - 34", "35 - 44", "45 - 54", "55+"],
    ];

    const answerKeys = [
        "erster_einkauf",
        "service",
        "ambiente",
        "auswahl",
        "alter",
    ];

    useEffect(() => {
        console.log(answers);
        //eslint-disable-next-line
    }, [step]);

    function handleAnswerClick(answer) {
        //TODO: switch answer index if 2 is best option not 3 (do in dashboard not here)
        setAnswers({...answers, [answerKeys[step]]: answer});
        setStep(step + 1);
    }

    //TODO: this is glued together, clean up code
    return(
            step != 0 ?
                step != 4 ?
                    <div className="singleChoiceAnswer">
                        <div className={"answer"} onClick={() => {handleAnswerClick(4)}}>{answerTexts[step][0]}</div>
                        <div className={"answer"} onClick={() => {handleAnswerClick(3)}}>{answerTexts[step][1]}</div>
                        <div className={"answer"} onClick={() => {handleAnswerClick(2)}}>{answerTexts[step][2]}</div>
                        <div className={"answer"} onClick={() => {handleAnswerClick(1)}}>{answerTexts[step][3]}</div>
                    </div> :
                    <div className="singleChoiceAnswer">
                        <div style={{textAlign: "center"}} className={"answer"} onClick={() => {handleAnswerClick(5)}}>{answerTexts[step][0]}</div>
                        <div style={{textAlign: "center"}} className={"answer"} onClick={() => {handleAnswerClick(4)}}>{answerTexts[step][1]}</div>
                        <div style={{textAlign: "center"}} className={"answer"} onClick={() => {handleAnswerClick(3)}}>{answerTexts[step][2]}</div>
                        <div style={{textAlign: "center"}} className={"answer"} onClick={() => {handleAnswerClick(2)}}>{answerTexts[step][3]}</div>
                        <div style={{textAlign: "center"}} className={"answer"} onClick={() => {handleAnswerClick(1)}}>{answerTexts[step][4]}</div>
                    </div> : 
                    <div style={{flexDirection: "row", padding: "0", justifyContent: "center", gap: "20px"}} className="singleChoiceAnswer">
                        <div 
                            style={
                                {
                                    textAlign: "center",
                                    margin: "0",
                                    width: "25%",
                                    paddingTop: "12.5%",
                                    paddingBottom: "12.5%",
                                    borderRadius: "30px"
                                }
                            } className={"answer"} onClick={() => {handleAnswerClick(true)}}>{answerTexts[step][0]}</div>
                        <div 
                            style={
                                {
                                    textAlign: "center",
                                    margin: "0",
                                    width: "25%",
                                    paddingTop: "12.5%",
                                    paddingBottom: "12.5%",
                                    borderRadius: "30px"
                                }
                            } className={"answer"} onClick={() => {handleAnswerClick(false)}}>{answerTexts[step][1]}</div>
                    </div>
    );
}