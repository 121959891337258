import React, { useEffect } from "react";
import "./Verify.css";
import { account } from "../../data/initAppwrite";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import logo from '../../assets/Logo_vs2_gold.png';

export default function Verify() {
    const navigate = useNavigate();

    const params = new URLSearchParams(window.location.search);
    const userId = params.get("userId");
    const secret = params.get("secret");

    useEffect(() => {
        async function updateVerification() {
            //TODO check if really updated and display to user
            if (userId && secret) {
                try {
                    const result = await account.updateVerification(userId, secret);
                    console.log("Verification successful:", result);
                } catch (error) {
                    console.error("Verification failed:", error.message);
                    navigate("/error");
                }
            } else {
                console.error("Verification parameters missing.");
                navigate("/error");
            }
        }

        updateVerification();
    }, []);

    

    return(
        <div className="verify">
            <div className="wrapper-verify">
                <div className="imageWrapper-survey">
                    <img src={logo} className="App-logo" alt="logo" />
                </div>
                <h1>E-Mail verifiziert!</h1>
                Deine E-Mail-Adresse wurde nun verifiziert und du nimmst jetzt am Gewinnspiel teil!
                <br/>
                Diesen Tab kannst du nun schließen.
                <br/>
                <br/>
                Viel Glück!
            </div>
            <Footer/>
        </div>
    );
}