import React from "react";
import "./Thx.css";
import Footer from "../Footer";

//TODO: restrict route access only when Survey successful allowed

export default function Thx() {
    return(
        <div className="Thx">
            <div className="content">
            <h2 className="thx-title">Das hat geklappt!</h2>
            <h1>🎉</h1>
            <h2 className="thx-text">
                Danke für Ihre Teilnahme, bitte klicken Sie
                auf den Link in der Bestätigungsmail<br/>die wir
                Ihnen soeben geschickt haben um am Gewinnspiel
                teilzunehmen - falls Sie keine Mail erhalten
                haben,<br/>prüfen Sie auch Ihren Spamordner oder
                kontaktieren Sie uns.    
            </h2>
            <br/>
            <h2 className="thx-text">Bis bald!</h2>
            </div>
            <Footer/>
        </div>
    );
}