import {databases} from "./initAppwrite";
import {ID} from "appwrite";

export default async function sendSurveyData(data) {
    try {
        const response = await databases.createDocument(
            '669151b9003d5915f5ca',
            '669151f10001e786e935',
            ID.unique(),
            data
        );
        console.log('Survey data sent: ', response);
        return true;
    } catch (e) {
        console.log('Error sending survey data: ', e);
        return false;
    }
}