import React from "react";
import logo from '../../assets/Logo_vs2_weiß.png';
import "./Start.css";
import Footer  from "../Footer.js";
import {Link} from "react-router-dom";


export default function Start() {
    return(
    <div className="App">
      <div className="start-content">
      <div className="imageWrapper-start">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
        <h2 className='title'>Willkommen beim<br/> Lederwaren Acker Gewinnspiel!</h2>
        <p className="startText">
          Jetzt in <span style={{fontWeight: 900, fontSize: "larger"}}>60 Sekunden</span> Umfrage<br/>
          beantworten und Chance auf<br/>
          1 von 3 Einkaufsgutscheinen im Wert<br/>
          von <span style={{fontWeight: 900, fontSize: "larger"}}><u>100€</u></span> sichern! Wir freuen uns über Ihr ehrliches Feedback!
        </p>
        <Link to="/survey" className="link">
          <button className='start-button'>LOS GEHT'S&emsp;🚀</button>
        </Link>
       {/*<Link to="/teilnahmebedingungen" className="link">Teilnahmebedingungen</Link>
        <Link to="/datenschutz" className="link">Datenschutz</Link><br/>
        <small>
          <Link to="/teilnahmebedingungen" className="link">Teilnahmebedingungen</Link>
           und Infos zum 
          <Link to="/datenschutz" className="link">Datenschutz</Link><br/>
        </small>*/}
      </div>
      <Footer/>
    </div>
    );
}