import {React} from "react";
import "./ProgressBar.css";

export default function ProgressBar({currentStep}) {
    let progressText = "";
    let emojis = ["🙌", "💪", "👍", "🙌"];
    if (currentStep + 1 < 4) {
        progressText = `Frage ${currentStep + 1} von 5 ${emojis[currentStep]}`;
    } else if (currentStep + 1 == 4) {
        progressText = "Fast Geschafft! 😁";
    } else if (currentStep + 1 == 5) {
        progressText = "Letzte Frage! 🙋";
    } else {
        progressText = "Geschafft! 🥳"
    }
    return(
        <div className="progress-text">
            <h2>{progressText}</h2>
        </div>
        /*<div className="ProgressBar">
            <ProgressPoint index={0} currentStep={currentStep}/>
            <ProgressPoint index={1} currentStep={currentStep}/>
            <ProgressPoint index={2} currentStep={currentStep}/>
            <ProgressPoint index={3} currentStep={currentStep}/>
            <ProgressPoint index={4} currentStep={currentStep}/>
        </div>*/
    );
}

/*function ProgressPoint({index, currentStep}) {
    let shouldColor = currentStep >= index;
    let style = 
        shouldColor ?
        "progress-point done" : 
        "progress-point";
    return(
        <div className={style}></div>
    );
}*/