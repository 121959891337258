import React from "react";
import Markdown from 'react-markdown';
import "./Teilnahmebedingungen.css";

export default function Teilnahmebedingungen() {
    const markdown = `# Teilnahmebedingungen

# Dieses Gewinnspiel wird veranstaltet von:

Lederwaren Acker GmbH  
Königstraße 28  
70173 Stuttgart  
GERMANY

# 1\. In welchem Zeitraum kann ich teilnehmen?

Das Gewinnspiel beginnt am 03.12.2024 um 18:00 Uhr (MEZ) und endet am 01.03.2025 um 18:00 Uhr (MEZ). 

# 2\. Wie funktioniert’s?

Um am Gewinnspiel teilnehmen zu können müssen Sie folgende Voraussetzungen erfüllen:

- Umfrage vollständig ausfüllen  
- Vor- und Nachname korrekt angeben  
- E-Mail-Adresse korrekt angeben  
- Teilnahmebedingungen und Datenschutzerklärung dieses Gewinnspiels akzeptieren

# 3\. Was kann ich gewinnen?

Es gibt insgesamt einen Preispool von insgesamt 300,00€ (3x 100,00€) in Form von Einkaufsgutscheinen für Lederwaren Acker GmbH (für die Einlösung gelten die Bestimmungen der Lederwaren Acker GmbH) und 3 Gewinner:innen. Die Gewinner:innen werden von Lederwaren Acker GmbH via E-Mail benachrichtigt. Die Gewinner:innen werden, sofern nicht anders mit ebendiesen vereinbart, nicht öffentlich bekanntgegeben.

# 4\. Wer darf teilnehmen?

Teilnahmeberechtigt sind alle Personen, die zum Zeitpunkt der Teilnahme mindestens 18 Jahre alt sind und ihren Erstwohnsitz in Deutschland haben. Mitarbeiter:innen der Lederwaren Acker GmbH sowie deren Familienangehörige sind nicht zur Teilnahme am Gewinnspiel berechtigt. 

# 5\. Kann ich von dem Gewinnspiel ausgeschlossen werden?

Ein Verstoß gegen diese Teilnahmebedingungen berechtigt die Lederwaren Acker GmbH, Sie von der Teilnahme am Gewinnspiel auszuschließen. Die Lederwaren Acker GmbH behält sich das Recht vor, Personen, bei denen der Verdacht besteht, dass sie sich bei der Teilnahme am Gewinnspiel unerlaubter Hilfsmittel bedienen oder anderweitig versuchen, sich oder Dritten durch Manipulation Vorteile zu verschaffen, oder falsche Angaben machen, von der Teilnahme auszuschließen. Auch ein Verstoß gegen die Allgemeinen Geschäftsbedingungen der Lederwaren Acker GmbH berechtigen diese, Sie vom Gewinnspiel auszuschließen.

# 6\. Weitere Informationen:

FÜR ALLE GEWINNE GILT:  
Gewinnansprüche sind nicht auf andere Personen übertragbar. Umtausch oder eine Barauszahlung des Gewinns ist nicht möglich. Sollte beim Versand eines Einkaufsgutscheins die Sendung durch den Versanddienstleister nicht ordnungsgemäß zugestellt werden, wird der Veranstalter sich bemühen, den Versanddienstleister zur Regulierung des Schadens heranzuziehen. Ein Anspruch auf den Gewinn besteht in diesem Fall nicht.

WEITERE TEILNAHMEBEDINGUNGEN:  
Teilnehmer können nur einmal am Gewinnspiel teilnehmen. Alle darüber hinaus ausgefüllten Umfragen haben keinen Einfluss auf die Gewinnchance des Teilnehmers.  

SONSTIGES:

Für Organisation und Kosten für An- und Abreise sowie Übernachtungskosten zur Übergabe des Preises sind die Gewinner verantwortlich.

Lederwaren Acker GmbH übernimmt – soweit rechtlich zulässig – keine Haftung für Irrtümer oder Druckfehler sowie für im Zusammenhang mit der Bereitstellung und Inanspruchnahme von Gewinnen möglicherweise entstehende Schäden, es sei denn, sie sind auf Vorsatz oder grobe Fahrlässigkeit von Lederwaren Acker GmbH zurückzuführen.

Lederwaren Acker GmbH behält sich das Recht vor, das Gewinnspiel auch ohne Angabe von Gründen zu verlängern oder vorzeitig zu beenden. Lederwaren Acker GmbH kann nicht für technische Störungen verantwortlich gemacht werden. Der Rechtsweg ist ausgeschlossen. 

Die Gewinner:innen müssen ihren Gewinn vor Ort bei Lederwaren Acker Königstraße 28 in 70173 Stuttgart abholen. Wird der Gewinn nicht binnen 14 Tagen nach Benachrichtigung der Gewinner:innen abgeholt, so verfällt dieser.

Der Rechtsweg ist ausgeschlossen.

Lederwaren Acker GmbH behält sich das Recht vor, das Gewinnspiel jederzeit und ohne eine Angabe von Gründen, zu ändern oder abzubrechen.

# 7\. Anwendbares Recht

Für sämtliche Rechtsbeziehungen der Parteien gilt das Recht der Bundesrepublik Deutschland. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als nicht der gewährte Schutz durch zwingende Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen gewöhnlichen Aufenthalt hat, entzogen wird.

# 8\. Veranstalter:

Das Lederwaren Acker GmbH Gewinnspiel wird von der Lederwaren Acker GmbH, Königstraße 28, 70173 Stuttgart, veranstaltet.

# 9\. Datenschutz

Verantwortlich für Datenverarbeitungen im Zusammenhang mit dem Gewinnspiel ist:

Lederwaren Acker GmbH  
Königstraße 28  
70173 Stuttgart 

**9.1. Gewinnspiel & Umfrage**

**9.2. Verarbeitung Ihrer personenbezogenen Daten im Rahmen des Gewinnspiels**  
Wir verarbeiten Ihre personenbezogenen Daten zur ordnungsgemäßen Durchführung und Abwicklung des Gewinnspiels und insbesondere zur Benachrichtigung im Falle eines Gewinnes.  
Zur Durchführung des Gewinnspiels werden der Vor- und Nachname des jeweiligen Teilnehmers sowie dessen E-Mail-Adresse erhoben und gespeichert. Eine Weitergabe Ihrer Daten an Dritte erfolgt nicht.  
Rechtsgrundlage für diese Verarbeitung Ihrer personenbezogenen Daten ist Art. 6 Abs. 1 lit. b DSGVO (Durchführung vorvertraglicher Maßnahmen und Erfüllung eines Vertrages).  
Sofern wir den Vor- und Zunamen des Gewinners/der Gewinner separat auf unserem Profil bzw. unserer Fanseite veröffentlichen oder extern, etwa in unserem Online-Shop, bekannt geben, erfolgt dies nur bei Vorliegen einer vorher eingeholten, ausdrücklichen Einwilligung des Gewinners/der Gewinner gemäß Art. 6 Abs. 1 lit. a DSGVO.

**9.3. Umfrage**  
Nach Beendigung des Gewinnspiels werden Ihre im Rahmen des Gewinnspiels verarbeiteten personenbezogenen Daten spätestens drei Monate nach dem Ende des Gewinnspiels gelöscht, die Umfrageergebnisse (Antworten) bleiben erhalten, jedoch lediglich in ausschließlich anonymisierter Form, das heißt ohne Namen, E-Mail-Adressen oder andere direkt personenbezogene Daten. 

**9.4. Speicherdauer**  
Nach Beendigung des Gewinnspiels werden Ihre im Rahmen des Gewinnspiels verarbeiteten personenbezogenen Daten spätestens drei Monate nach dem Ende des Gewinnspiels gelöscht, sofern keine wirksame Einwilligung zur Weiterverarbeitung (etwa bezüglich des Newsletter-Versandes) vorliegt und/oder unsererseits kein berechtigtes Interesse an der weiteren Speicherung fortbesteht (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen).

**9.5. Ihre Betroffenenrechte**

Das geltende Datenschutzrecht räumt Ihnen die nachstehend aufgeführten Betroffenenrechte ein:

**Auskunftsrecht gemäß Art. 15 DSGVO:**  
Sie haben insbesondere ein Recht auf Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten, die Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empfänger oder Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer bzw. die Kriterien für die Festlegung der Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbehörde, die Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftige Informationen über die involvierte Logik und die Sie betreffende Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien gemäß Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittländer bestehen;

**Recht auf Berichtigung gemäß Art. 16 DSGVO:**  
Sie haben ein Recht auf unverzügliche Berichtigung Sie betreffender unrichtiger Daten und/oder Vervollständigung Ihrer bei uns gespeicherten unvollständigen Daten;

**Recht auf Löschung gemäß Art. 17 DSGVO:**  
Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten bei Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;

**Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO:**  
Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange die von Ihnen bestrittene Richtigkeit Ihrer Daten überprüft wird, wenn Sie eine Löschung Ihrer Daten wegen unzulässiger Datenverarbeitung ablehnen und stattdessen die Einschränkung der Verarbeitung Ihrer Daten verlangen, wenn Sie Ihre Daten zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, nachdem wir diese Daten nach Zweckerreichung nicht mehr benötigen oder wenn Sie Widerspruch aus Gründen Ihrer besonderen Situation eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten Gründe überwiegen;

**Recht auf Unterrichtung gemäß Art. 19 DSGVO:**  
Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht das Recht zu, über diese Empfänger unterrichtet zu werden.

**Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO:**  
Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen, soweit dies technisch machbar ist;

**Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3 DSGVO:**  
Sie haben das Recht, eine einmal erteilte Einwilligung in die Verarbeitung von Daten jederzeit mit Wirkung für die Zukunft zu widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten unverzüglich löschen, sofern eine weitere Verarbeitung nicht auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung gestützt werden kann. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt;

**Recht auf Beschwerde gemäß Art. 77 DSGVO:**  
Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt, haben Sie \- unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs \- das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des mutmaßlichen Verstoßes.

**Weitere Informationen entnehmen Sie bitte der \[Datenschutzerklärung\].**# Teilnahmebedingungen

# Dieses Gewinnspiel wird veranstaltet von:

Lederwaren Acker GmbH  
Königstraße 28  
70173 Stuttgart  
GERMANY

# 1\. In welchem Zeitraum kann ich teilnehmen?

Das Gewinnspiel beginnt am 03.12.2024 um 18:00 Uhr (MEZ) und endet am 01.03.2025 um 18:00 Uhr (MEZ). 

# 2\. Wie funktioniert’s?

Um am Gewinnspiel teilnehmen zu können müssen Sie folgende Voraussetzungen erfüllen:

- Umfrage vollständig ausfüllen  
- Vor- und Nachname korrekt angeben  
- E-Mail-Adresse korrekt angeben  
- Teilnahmebedingungen und Datenschutzerklärung dieses Gewinnspiels akzeptieren

# 3\. Was kann ich gewinnen?

Es gibt insgesamt einen Preispool von insgesamt 300,00€ (3x 100,00€) in Form von Einkaufsgutscheinen für Lederwaren Acker GmbH (für die Einlösung gelten die Bestimmungen der Lederwaren Acker GmbH) und 3 Gewinner:innen. Die Gewinner:innen werden von Lederwaren Acker GmbH via E-Mail benachrichtigt. Die Gewinner:innen werden, sofern nicht anders mit ebendiesen vereinbart, nicht öffentlich bekanntgegeben.

# 4\. Wer darf teilnehmen?

Teilnahmeberechtigt sind alle Personen, die zum Zeitpunkt der Teilnahme mindestens 18 Jahre alt sind und ihren Erstwohnsitz in Deutschland haben. Mitarbeiter:innen der Lederwaren Acker GmbH sowie deren Familienangehörige sind nicht zur Teilnahme am Gewinnspiel berechtigt. 

# 5\. Kann ich von dem Gewinnspiel ausgeschlossen werden?

Ein Verstoß gegen diese Teilnahmebedingungen berechtigt die Lederwaren Acker GmbH, Sie von der Teilnahme am Gewinnspiel auszuschließen. Die Lederwaren Acker GmbH behält sich das Recht vor, Personen, bei denen der Verdacht besteht, dass sie sich bei der Teilnahme am Gewinnspiel unerlaubter Hilfsmittel bedienen oder anderweitig versuchen, sich oder Dritten durch Manipulation Vorteile zu verschaffen, oder falsche Angaben machen, von der Teilnahme auszuschließen. Auch ein Verstoß gegen die Allgemeinen Geschäftsbedingungen der Lederwaren Acker GmbH berechtigen diese, Sie vom Gewinnspiel auszuschließen.

# 6\. Weitere Informationen:

FÜR ALLE GEWINNE GILT:  
Gewinnansprüche sind nicht auf andere Personen übertragbar. Umtausch oder eine Barauszahlung des Gewinns ist nicht möglich. Sollte beim Versand eines Einkaufsgutscheins die Sendung durch den Versanddienstleister nicht ordnungsgemäß zugestellt werden, wird der Veranstalter sich bemühen, den Versanddienstleister zur Regulierung des Schadens heranzuziehen. Ein Anspruch auf den Gewinn besteht in diesem Fall nicht.

WEITERE TEILNAHMEBEDINGUNGEN:  
Teilnehmer können nur einmal am Gewinnspiel teilnehmen. Alle darüber hinaus ausgefüllten Umfragen haben keinen Einfluss auf die Gewinnchance des Teilnehmers.  

SONSTIGES:

Für Organisation und Kosten für An- und Abreise sowie Übernachtungskosten zur Übergabe des Preises sind die Gewinner verantwortlich.

Lederwaren Acker GmbH übernimmt – soweit rechtlich zulässig – keine Haftung für Irrtümer oder Druckfehler sowie für im Zusammenhang mit der Bereitstellung und Inanspruchnahme von Gewinnen möglicherweise entstehende Schäden, es sei denn, sie sind auf Vorsatz oder grobe Fahrlässigkeit von Lederwaren Acker GmbH zurückzuführen.

Lederwaren Acker GmbH behält sich das Recht vor, das Gewinnspiel auch ohne Angabe von Gründen zu verlängern oder vorzeitig zu beenden. Lederwaren Acker GmbH kann nicht für technische Störungen verantwortlich gemacht werden. Der Rechtsweg ist ausgeschlossen. 

Die Gewinner:innen müssen ihren Gewinn vor Ort bei Lederwaren Acker Königstraße 28 in 70173 Stuttgart abholen. Wird der Gewinn nicht binnen 14 Tagen nach Benachrichtigung der Gewinner:innen abgeholt, so verfällt dieser.

Der Rechtsweg ist ausgeschlossen.

Lederwaren Acker GmbH behält sich das Recht vor, das Gewinnspiel jederzeit und ohne eine Angabe von Gründen, zu ändern oder abzubrechen.

# 7\. Anwendbares Recht

Für sämtliche Rechtsbeziehungen der Parteien gilt das Recht der Bundesrepublik Deutschland. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als nicht der gewährte Schutz durch zwingende Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen gewöhnlichen Aufenthalt hat, entzogen wird.

# 8\. Veranstalter:

Das Lederwaren Acker GmbH Gewinnspiel wird von der Lederwaren Acker GmbH, Königstraße 28, 70173 Stuttgart, veranstaltet.

# 9\. Datenschutz

Verantwortlich für Datenverarbeitungen im Zusammenhang mit dem Gewinnspiel ist:

Lederwaren Acker GmbH  
Königstraße 28  
70173 Stuttgart 

**9.1. Gewinnspiel & Umfrage**

**9.2. Verarbeitung Ihrer personenbezogenen Daten im Rahmen des Gewinnspiels**  
Wir verarbeiten Ihre personenbezogenen Daten zur ordnungsgemäßen Durchführung und Abwicklung des Gewinnspiels und insbesondere zur Benachrichtigung im Falle eines Gewinnes.  
Zur Durchführung des Gewinnspiels werden der Vor- und Nachname des jeweiligen Teilnehmers sowie dessen E-Mail-Adresse erhoben und gespeichert. Eine Weitergabe Ihrer Daten an Dritte erfolgt nicht.  
Rechtsgrundlage für diese Verarbeitung Ihrer personenbezogenen Daten ist Art. 6 Abs. 1 lit. b DSGVO (Durchführung vorvertraglicher Maßnahmen und Erfüllung eines Vertrages).  
Sofern wir den Vor- und Zunamen des Gewinners/der Gewinner separat auf unserem Profil bzw. unserer Fanseite veröffentlichen oder extern, etwa in unserem Online-Shop, bekannt geben, erfolgt dies nur bei Vorliegen einer vorher eingeholten, ausdrücklichen Einwilligung des Gewinners/der Gewinner gemäß Art. 6 Abs. 1 lit. a DSGVO.

**9.3. Umfrage**  
Nach Beendigung des Gewinnspiels werden Ihre im Rahmen des Gewinnspiels verarbeiteten personenbezogenen Daten spätestens drei Monate nach dem Ende des Gewinnspiels gelöscht, die Umfrageergebnisse (Antworten) bleiben erhalten, jedoch lediglich in ausschließlich anonymisierter Form, das heißt ohne Namen, E-Mail-Adressen oder andere direkt personenbezogene Daten. 

**9.4. Speicherdauer**  
Nach Beendigung des Gewinnspiels werden Ihre im Rahmen des Gewinnspiels verarbeiteten personenbezogenen Daten spätestens drei Monate nach dem Ende des Gewinnspiels gelöscht, sofern keine wirksame Einwilligung zur Weiterverarbeitung (etwa bezüglich des Newsletter-Versandes) vorliegt und/oder unsererseits kein berechtigtes Interesse an der weiteren Speicherung fortbesteht (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen).

**9.5. Ihre Betroffenenrechte**

Das geltende Datenschutzrecht räumt Ihnen die nachstehend aufgeführten Betroffenenrechte ein:

**Auskunftsrecht gemäß Art. 15 DSGVO:**  
Sie haben insbesondere ein Recht auf Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten, die Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empfänger oder Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer bzw. die Kriterien für die Festlegung der Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbehörde, die Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftige Informationen über die involvierte Logik und die Sie betreffende Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien gemäß Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittländer bestehen;

**Recht auf Berichtigung gemäß Art. 16 DSGVO:**  
Sie haben ein Recht auf unverzügliche Berichtigung Sie betreffender unrichtiger Daten und/oder Vervollständigung Ihrer bei uns gespeicherten unvollständigen Daten;

**Recht auf Löschung gemäß Art. 17 DSGVO:**  
Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten bei Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;

**Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO:**  
Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange die von Ihnen bestrittene Richtigkeit Ihrer Daten überprüft wird, wenn Sie eine Löschung Ihrer Daten wegen unzulässiger Datenverarbeitung ablehnen und stattdessen die Einschränkung der Verarbeitung Ihrer Daten verlangen, wenn Sie Ihre Daten zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, nachdem wir diese Daten nach Zweckerreichung nicht mehr benötigen oder wenn Sie Widerspruch aus Gründen Ihrer besonderen Situation eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten Gründe überwiegen;

**Recht auf Unterrichtung gemäß Art. 19 DSGVO:**  
Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht das Recht zu, über diese Empfänger unterrichtet zu werden.

**Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO:**  
Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen, soweit dies technisch machbar ist;

**Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3 DSGVO:**  
Sie haben das Recht, eine einmal erteilte Einwilligung in die Verarbeitung von Daten jederzeit mit Wirkung für die Zukunft zu widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten unverzüglich löschen, sofern eine weitere Verarbeitung nicht auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung gestützt werden kann. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt;

**Recht auf Beschwerde gemäß Art. 77 DSGVO:**  
Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt, haben Sie \- unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs \- das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des mutmaßlichen Verstoßes.

**Weitere Informationen entnehmen Sie bitte der [Datenschutzerklärung](https://gewinnspiel-lederwarenacker.de/datenschutz).**`;
    return(<Markdown>{markdown}</Markdown>);
}